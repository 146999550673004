import React from 'react';
import '../App.css';
import Navbar from '../components/navbar'
import Footer from '../components/footer'
import original from '../infuse/original-design.png'
import newer from '../infuse/new-design.png'
import drawing from '../infuse/drawing.png'



const ChargerPage = () => {
  return (  
    <div className="Home">
      <header className="header_area">
        <Navbar></Navbar>
      </header>

      <article className="project_details_area">
          <div className="project_post p_120">
              <h1>CASPr: Charging Accessory for Supplemental Power</h1>
              <h2>Patent Application</h2>
              <div className="entry">
                  <hr></hr>
                  <h2 id="redesign">Introduction</h2>
                  <p>
                      Welcome to the first project on my page! In this post, I'll be talking about a project I did during an internship way back in high school. Even though this was quite a while back, I'm proud
                      of the work I did. It was an engagement that lasted around 6 months, and I think the work that my team and I did made quite the impact. The project deals with medical devices
                      in developing countries, more specifically intravenous IV) therapy pumps in Malawi. 
                  </p>
                  <p>
                    Intravenous therapy 


                  IV therapy is a common treatment for dehydration, a dangerous problem in Africa. Yet, in developing countries, medical facilities often lack electrical and financial resources
                  to be able to utilize modern IV pumps. Medical personnel is often low in count and insufficent in monitoring and administering IV. My team and I were tasked with designing
                  a device that would deliver a perscribed dose of IV fluid to patients.
                  </p>
                  <p>
                  <b><u>Goals</u></b>
                    <ul>
                      <li>Create a device to aid doctors in providing prolonged IV care with minimal assistance from a caretaker</li>
                      <li>Use affordable materials commonly available in developing countires</li>
                      <li>Devise a mechanism to automate the delivery of a set volume of IV fluid</li>
                    </ul>
                  </p>
                  <p>
                      At the time, a prototype called <b>Infuse</b> had already been developed by a team of college students. Since my team was really just optimizing this pre-existing
                      design, our revamp of the device was logically named <b>Infuse II</b>. 
                      The finished device was then taken to Malawi and tested by another team.
                  </p>
                  <h2 id="original_design">The Original Design</h2>
                  <p>
                      The original design used a simple lever design that reaches equilibrium at a prescribed dosage. At equilibrium the lever arm would trigger a cutoff device, which would stop the flow of IV fluid. 
                      The original design can be seen in the picture below:
                  </p>
                  <p><img src={original} alt="" /></p>

                  <p><strong>Note:</strong> This is the OLD design.</p>
                  <h2 id="redesign">Our Redesign</h2>
                  <p>
                      Though the original design was effective, its biggest flaw was that the lever arm took up too much space.
                  </p>
                  <p>
                  <b><u>Our concept</u></b>
                    <ul>
                      <li>Redesign the stand to be telescopic, allowing for height adjustment</li>
                      <li>Redesign the cutoff mechanism using a pivoting pin to release a mass</li>
                      <li>Replace the base with PVC instead of wood</li>
                      <li>Replace the lever with a pully system</li>
                    </ul>
                  </p>
                  <p>
                    Our redesign is shown below:
                    <img className="new_infuse" src={newer} alt="" />
                  </p>
                  <p><strong>Note:</strong> You can click <a href={drawing}>here</a> for an isometric drawing.</p>
              </div>
          </div>
          {/* <ContactMe></ContactMe> */}
      </article>


      <footer className="footer_area p_120">
        <Footer></Footer>
      </footer>
    </div>
  )
}

export default ChargerPage;
