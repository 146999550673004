import React from 'react'

const Footer = () => {
	return (
		<div className="container">
			<div className="footer_inner">
					<aside className="f_widget ab_widget">
						<div className="f_title">
							<h3>Connect with me on <a href="https://www.linkedin.com/in/mchang7337" target="_blank" rel="noopener noreferrer">LinkedIn</a></h3>
						</div>
						<p>
							&copy; Copyright {(new Date().getFullYear())} by Michael Chang
						</p>
						<p>
							Made with&nbsp;<i className="fa fa-heart-o" aria-hidden="true"> </i> using React
						</p>
					</aside>
			</div>
		</div>
	)
}

export default Footer;