import React, { useState } from 'react';

const contact = {
	name: '',
	subject: '',
	message: '',
}

const ContactMe = () => {
	const [{name, subject, message}, setContact] = useState(contact);

	const resetForm = () => {
		setContact({ ...contact });
	}
	const onChange = e => {
		const {name, subject, message, value} = e.target;
		setContact(prevState => ({ ...prevState, [name]: value, [subject]: value, [message]: value}));
	}
	const handleFormSubmit = (e) => {
		e.preventDefault();
		if (document.getElementById("error_message")) {
			document.getElementById("error_message").remove();
		};
		if (document.getElementById("social_media")) {
			document.getElementById("social_media").remove();
		};
		let temp = [];
		for (let i = 0; i < 3; i++) {
			if (Object.values({name, subject, message})[i] === "") {
				temp.push(Object.keys({name, subject, message})[i]);
			}
		}

		let error_message = "";
		var insert_error = document.createElement("div");
		insert_error.id = "error_message";
		var element = document.getElementById("contact_form");

		if (temp.length === 3) {
			error_message = `The <c>${temp[0]}</c>, <c>${temp[1]}</c>, and <c>${temp[2]}</c> fields are required! Please fill out these fields and try again.`
			insert_error.innerHTML = error_message;
			element.appendChild(insert_error);
		} else if (temp.length === 2) {
			error_message = `The <c>${temp[0]}</c> and <c>${temp[1]}</c> fields are required! Please fill out these fields and try again.`
			insert_error.innerHTML = error_message;
			element.appendChild(insert_error);
		} else if (temp.length === 1) {
			error_message = `The <c>${temp[0]}</c> field is required! Please fill out this field and try again.`
			insert_error.innerHTML = error_message;
			element.appendChild(insert_error);
		} else {
			var link = "mailto:cmc8dm@virginia.edu"
				+ "?&subject=" + subject
				+ "&body=Hi, my name is " + name +  "! %0D%0A %0D%0A" 
				+ "%09" + message.replace(/\n/g, '%0D%0A %09');
				window.location.href = link;
				
			var alternative = document.createElement("div");
			alternative.id = "social_media";
			var newelement = document.getElementById("contact_page");
			alternative.innerHTML = `<p><i><b>If that didn't work</b></i>, feel free to reach out on <u><i>social media</i></u> instead:</p>
			<a href="https://www.linkedin.com/in/mchang7337/" target="_blank" rel="noopener noreferrer">
				<i class="fa fa-linkedin" aria-hidden="true"></i>
			</a>
			<a href="https://www.instagram.com/mike_not_chuck/" target="_blank" rel="noopener noreferrer">
				<i class="fa fa-instagram" aria-hidden="true"></i>
			</a>
			<a href="https://www.facebook.com/changm7337/" target="_blank" rel="noopener noreferrer">
				<i class="fa fa-facebook" aria-hidden="true"></i>
			</a>`;
			newelement.appendChild(alternative);
			window.scrollBy(0, 150);
		}
	}
	return (
		<div>
			<div className="container">
				<div className="contact_title">Contact Me</div>

				<div className="contact_page" id="contact_page">
				<h2>Get in Touch!</h2>
				<p>If you're interested in collaborating, curious about something on my site, or just want to say hello, please reach out and send me a message. 
					I love meeting new people, and that includes virtually as well.</p>
				<p><b>Have an <i>email client </i> set up? Message me below:</b></p>
					<div>
						<form className="contact_form" id="contact_form">
								<div className="form-group">
									<label className="contact_name">Name</label>
									{/* placeholder="Name" */}
									<input type="text" className="form-control" id="name" name="name" value={ name } onChange={ onChange } />
								</div>
								<div className="form-group">
									<label className="contact_subject">Subject</label>
									<input type="text" className="form-control" id="subject" name="subject" value={ subject } onChange={ onChange } />
								</div>
							<div className="form-group">
								<label className="contact_message">Message</label>
								<div className="scroll_area">
									<textarea className="form-control" name="message" id="message" rows="1" value={ message } onChange={ onChange }
									></textarea>
								</div>
							</div>
						</form>
						<div className="compose">
							<button type="submit" value="submit" onClick={ e => handleFormSubmit(e) } className="btn submit_btn">Compose Email</button>
							<button type="reset" value="reset" onClick={ resetForm } className="btn reset_btn">Reset</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default ContactMe;