import React from 'react'

const Box = () => {
	return (
		<div className="container">
			<div className="refresher-heading">Need a refresher?</div>
			<div className="row">
				<div className="col-lg-4">
					<div className="categories_post">
						<img src="img/blog/cat-post/aboutme.png" alt="post" />
						<a href="/about#aboutme">
							<div className="categories_details">
								<div className="categories_text">
									<h5>About Me</h5>
									<div className="border_line"></div>
									<p>Just a little intro</p>
								</div>
							</div>
						</a>
					</div>
				</div>
				<div className="col-lg-4">
					<div className="categories_post">
						<img src="img/blog/cat-post/education.jpg" alt="post" />
						<a href="/about#education">
							<div className="categories_details">
								<div className="categories_text">
									<h5>Education</h5>
									<div className="border_line"></div>
									<p>See where I've studied</p>
								</div>
							</div>
						</a>
					</div>
				</div>
				<div className="col-lg-4">
					<div className="categories_post">
						<img src="img/blog/cat-post/work.png" alt="post" />
						<a href="/about#experience">
							<div className="categories_details">
								<div className="categories_text">
									<h5>Experience</h5>
									<div className="border_line"></div>
									<p>What have I done?</p>
								</div>
							</div>
						</a>
					</div>
				</div>
			</div>
		</div>
	)
}

export default Box;