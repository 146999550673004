import React from 'react'

const Projects = () => {
	return (
		<div>
			<div className="container">
				<div className="main_title">
					<h2>A Few of My Projects</h2>
					<p>Check them out!</p>
				</div>
				{/* <div className="isotope_fillter">
					<ul className="gallery_filter list">
						<li className="active" data-filter="*"><a href="#">All</a></li>
						<li data-filter=".brand"><a href="#">Software</a></li>
						<li data-filter=".manipul"><a href="#">Product Design</a></li>
					</ul>
				</div> */}
			</div>
			<div className="container">
				<div className="gallery_f_inner row imageGallery1">
					<div className="col-lg-4 col-md-4 col-sm-6 brand manipul creative">
						<div className="h_gallery_item">
							<div className="g_img_item">
								<img className="img-fluid" src="img/gallery/project/project-2.png" alt=""/>
								<a className="light" href="https://github.com/snac-cooperative/OpenRefine/tree/capstone-master?fbclid=IwAR2g3RLdG2qAxrwGPzk1vi76zJhIw-ajnKBH91ziIvrypo7qtpDkHvh7uQQ" target="_blank" rel="noopener noreferrer">
									<i className="fa fa-eye" aria-hidden="true"></i>
								</a>
							</div>
							<div className="g_item_text">
								<h4>SNAC OpenRefine Plugin</h4>
								<p>Software Plugin</p>
							</div>
						</div>
					</div>
					<div className="col-lg-4 col-md-4 col-sm-6 manipul creative design print">
						<div className="h_gallery_item">
							<div className="g_img_item">
								<img className="img-fluid" src="img/gallery/project/project-3.png" alt=""/>
								<a className="light" href="https://github.com/snac-cooperative/OpenRefine/tree/capstone-master?fbclid=IwAR2g3RLdG2qAxrwGPzk1vi76zJhIw-ajnKBH91ziIvrypo7qtpDkHvh7uQQ" target="_blank" rel="noopener noreferrer">
									<i className="fa fa-eye" aria-hidden="true"></i>
								</a>
							</div>
							<div className="g_item_text">
								<h4>Textbook Marketplace for Students</h4>
								<p>Web Application</p>
							</div>
						</div>
					</div>
					<div className="col-lg-4 col-md-4 col-sm-6 brand creative">
						<div className="h_gallery_item">
							<div className="g_img_item">
								<img className="img-fluid" src="img/gallery/project/project-6.png" alt=""/>
								<a className="light" href="https://github.com/c-mike-chang/face-rec" target="_blank" rel="noopener noreferrer">
									<i className="fa fa-eye" aria-hidden="true"></i>
								</a>
							</div>
							<div className="g_item_text">
								<h4>AWS Facial Recognition</h4>
								<p>ML Initiative</p>
							</div>
						</div>
					</div>
					<div className="col-lg-4 col-md-4 col-sm-6 brand manipul design">
						<div className="h_gallery_item">
							<div className="g_img_item">
								<img className="img-fluid" src="img/gallery/project/project-5.png" alt=""/>
								<a className="light" href="/ring">
									<i className="fa fa-eye" aria-hidden="true"></i>
								</a>
							</div>
							<div className="g_item_text">
								<h4>UVA Class Ring</h4>
								<p>Product Design</p>
							</div>
						</div>
					</div>
					<div className="col-lg-4 col-md-4 col-sm-6 brand creative print">
						<div className="h_gallery_item">
							<div className="g_img_item">
								<img className="img-fluid" src="img/gallery/project/project-4.png" alt=""/>
								<a className="light"  href="/charger">
									<i className="fa fa-eye" aria-hidden="true"></i>
								</a>
							</div>
							<div className="g_item_text">
								<h4>MacBook Pro Charging Case</h4>
								<p>Patent Design</p>
							</div>
						</div>
					</div>
					<div className="col-lg-4 col-md-4 col-sm-6 brand manipul design print">
						<div className="h_gallery_item">
							<div className="g_img_item">
								<img className="img-fluid" src="img/gallery/project/project-1.png" alt=""/>
								<a className="light" href="/infuse">
									<i className="fa fa-eye" aria-hidden="true"></i>
								</a>
							</div>
							<div className="g_item_text">
								<h4>Infuse Intravenous Therapy Device</h4>
								<p>Product Design</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default Projects;