import React from 'react'

const About = () => {
	window.addEventListener('load', function () {
		let college_heading = document.getElementsByClassName('panel')[0].getElementsByClassName("panel-heading")[0];
		let highschool_heading = document.getElementsByClassName('panel')[1].getElementsByClassName("panel-heading")[0];
		
		let college_body = document.getElementById("collapseOne");
		let highschool_body = document.getElementById("collapseTwo");
		
		
		let college_heading_selected = college_heading.getElementsByTagName('h4')[0].getElementsByTagName('a')[0]
		let highschool_heading_selected = highschool_heading.getElementsByTagName('h4')[0].getElementsByTagName('a')[0]
		
		
		college_heading.onclick = function() {
			if (college_heading_selected.classList.contains("collapsed") && !highschool_heading_selected.classList.contains("collapsed")) {
			highschool_heading_selected.className = "collapsed";
			highschool_body.className = "panel-collapse collapse";
			const element = document.getElementById('education-heading');
			const y = element.getBoundingClientRect().top + window.pageYOffset -100;
			window.scrollTo({top: y});      
			}
		}
		
		highschool_heading.onclick = function() {
			if (!college_heading_selected.classList.contains("collapsed") && highschool_heading_selected.classList.contains("collapsed")) {
			college_heading_selected.className = "collapsed";
			college_body.className = "panel-collapse collapse";
			const element = document.getElementById('education-heading');
			const y = element.getBoundingClientRect().top + window.pageYOffset -100;
			window.scrollTo({top: y});
			}
		}
	})
	return (
		<div className="container">
			<div className="row welcome_inner">
				<div className="about_me_title">About Me</div>
				<div className="about_me_headings">
					<h4>Currently in Arlington, VA</h4>
					<h3>Originally from Roanoke, VA</h3>
				</div>
					<div className="about_me_info">
						<img src="img/about_pic.jpg" alt=""/>
						<div className="about-me-text">			
							<div className="aboutAddReadMore aboutshowlesscontent">
								<p>
									Hey, my name's Charles Michael Chang, but I often go by <u>Mike</u> or <u>Michael</u>; either one's fine! This past May (2020), I graduated from the University of 
									Virginia with a Computer Science degree, and I'm on the hunt for <b>project management</b> or <b>consulting opportunities</b> in tech. 
								</p>
								<p>
									I grew up in Virginia, but my family is from Taiwan. As a benefit, I'm fluent in both <i>English</i> and <i>Mandarin Chinese</i>. During my school years,
									I was able to visit Taiwan every summer, and these visits really allowed me to meet a lot of different people and learn about different perspectives. 
								</p>
								{/* <p>
									I found a passion for tech early on in life. It helped that the education system I grew up in really pushed for technology, so I always
									had access to computers. I used to fool around and program batch files or edit HTML to prank my friends at school, and through these friendly pranks, I discovered
									the versatility of programming. It was programming's potential for <i>innovation</i> that led me to pursue Computer Science in college.

								</p>
								<p>
									It wasn't until later that I really showed an interest in business. For most of my life, my parents have owned a restaurant, where I started working in 
									High School. Being in the family business meant I had to do everything: dishwashing, waiting tables, washing dishes, you name it.
									But it was a great environment—interacting with the customers helped me become more <i><b>social</b></i>, and working multiple roles helped me learn <i><b>organization</b></i> and <i><b>time management</b></i>.
								</p> */}
								<p>
									Personally, I'm passionate about <i>technological literacy</i> and <i>product design</i>, and <i>work-flow optimization and management</i>. 
									By working within the tech space in a consulting role, I hope gain communication and organization skills to hopefully move into a <b> project management</b> role, 
									where I'd be able to leading an entire project through initiation, planning, execution, control, and completion!
								</p>
								<p>
									In my free time, you'll find me practicing Taekwondo, reading a fantasy novel, or trying new things with my friends and family.
									My fun fact is that I can do the splits! I also love socializing and meeting new people, so <a href="/contact">reach out</a> - let's chat!
								</p>
							</div>
						</div>
					</div>
			</div>
		</div>
	)
}

export default About;