import React from 'react'

const Experience = () => {
    return (
        <div className="exsk_row">
            <div className="exsk_col" >
                <div className="narrow-container">
                    <div className="experience-heading">Experiences</div>
                    <div className="experience_area">
                        <div className="timeline-centered">
                            <div className="timeline-entry">
                                <div className="timeline-entry-inner">
                                    <div className="timeline-icon color-5">
                                        <i className="icon-pen2" />
                                    </div>
                                    <div className="timeline-label-wrap">
                                        <div className="timeline-label">
                                            <div className="pos-date">
                                                Aug 2020 - Present
                                            </div>
                                            <h2>Deloitte and Touche LLP</h2>
                                            <div className="pos-desc">
                                                <div>Cyber Risk Advisory Analyst - Data Specialist </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="timeline-entry">
                                <div className="timeline-entry-inner">
                                    <div className="timeline-icon color-4">
                                        <i className="icon-pen2" />
                                    </div>
                                    <div className="timeline-label-wrap">
                                        <div className="timeline-label">
                                            <div className="pos-date">
                                                May 2019 - Aug 2019
                                            </div>
                                            <h2>Hubbell Lighting Inc. </h2>
                                            <div className="pos-desc">
                                                <div>Computer Science Intern</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="timeline-entry">
                                <div className="timeline-entry-inner">
                                    <div className="timeline-icon color-3">
                                        <i className="icon-pen2" />
                                    </div>
                                    <div className="timeline-label-wrap">
                                        <div className="timeline-label">
                                            <div className="pos-date">
                                                Jan 2019 - Jan 2020
                                            </div>
                                            <h2>HackCville </h2>
                                            <div className="pos-desc">
                                                <div>Program Coordinator</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="timeline-entry">
                                <div className="timeline-entry-inner">
                                    <div className="timeline-icon color-2">
                                        <i className="icon-pen2" />
                                    </div>
                                    <div className="timeline-label-wrap">
                                        <div className="timeline-label">
                                            <div className="pos-date">
                                                Jun 2018 - Aug 2018 | Dec 2018 - Jan 2019
                                            </div>
                                            <h2>Optical Cable Corporation </h2>
                                            <div className="pos-desc">
                                                <div>Information Technology Intern</div>
                                                <div>Marketing Intern</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="timeline-entry ">
                                <div className="timeline-entry-inner">
                                    <div className="timeline-icon color-none">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>    
                </div>
            </div>
            <div className="exsk_col" >
                <div className="narrow-container">
                    <div className="skills-heading">Skills</div>
                    <div className="skills_area">
                        <h2>Technical</h2>
                        <div className="skill_labels">
                            <div className="tech">C++</div>
                            <div className="tech">Command Line</div>
                            <div className="tech">CSS3</div>
                            <div className="tech">Data Visualization</div>
                            <div className="tech">Hadoop</div>
                            <div className="tech">HTML5</div>
                            <div className="tech">Java</div>
                            <div className="tech">Javascript</div>
                            {/* <div className="tech">Machine Learning</div> */}
                            {/* <div className="tech">Modeling</div> */}
                            <div className="tech">Python</div>
                            <div className="tech">Visual Basic for Applications</div>
                        </div>
                        <h2>Design & Visualization</h2>
                        <div className="skill_labels">
                            <div className="des_vis">Adobe Illustrator</div>
                            <div className="des_vis">AutoCAD</div>
                            <div className="des_vis">Autodesk Inventor</div>
                            <div className="des_vis">Photoshop</div>
                            <div className="des_vis">Power BI</div>
                            <div className="des_vis">Smartsheet</div>
                            <div className="des_vis">Tableu</div>
                        </div>
                        <h2>Project Management</h2>
                        <div className="skill_labels">
                            <div className="pm">Agile Methodology</div>
                            <div className="pm">Git Version Control</div>
                            <div className="pm">Rapid Prototyping</div>
                            <div className="pm">Storyboarding</div>
                            <div className="pm">UX Research</div>
                            <div className="pm">Wireframing</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Experience;