import React from 'react'

const Education = () => {
	return (
		<div className="narrow-container">
			<div className="row">
				<div className="education-heading" id="education-heading">Education</div>
			</div>
			<div className="row">
				<div className="col-md-12 animate-box" data-animate-effect="fadeInLeft">
					<div className="panel-group" id="accordion" role="tablist" aria-multiselectable="true">		
						<div className="panel">
							<div className="panel-heading" role="tab" id="headingOne">
								<h4 className="panel-title">
									<a data-toggle="collapse" data-parent="#accordion" href="#collapseOne" aria-expanded="true" aria-controls="collapseOne">College / University</a>
								</h4>
							</div>
							<div id="collapseOne" className="panel-collapse collapse show" role="tabpanel" aria-labelledby="headingOne">
								<div className="panel-body">
									<div className="panel-body-title">University of Virginia</div>
									<span className="heading-desc">Class of 2020 | Charlottesville, Virginia</span>
									<ul>
										<li>B.S. Computer Science</li>
										<li>Engineering Business Minor</li>
									</ul>
								</div>
							</div>
						</div>
						<div className="panel">
							<div className="panel-heading" role="tab" id="headingTwo">
								<h4 className="panel-title">
									<a className="collapsed" data-toggle="collapse" data-parent="#accordion" href="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">High School / Secondary Education</a>
								</h4>
							</div>
							<div id="collapseTwo" className="panel-collapse collapse" role="tabpanel" aria-labelledby="headingTwo">
								<div className="panel-body">
									<div className="row">
										<div className="col-md-6">
											<div className="panel-body-title">Cave Spring High School</div>
											<span className="heading-desc">2012-2016 | Roanoke, Virginia</span>
										</div>
										<div className="col-md-6">
											<div className="panel-body-title">Burton Center for Engineering</div>
											<span className="heading-desc">2012-2016 | Salem, Virginia</span>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default Education;