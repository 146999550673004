import React from 'react'
import resume from '../files/Resume.pdf'
const $ = window.$;

const Navbar = () => {
    return (
        <div className="main_menu">
            <nav className="navbar navbar-expand-lg navbar-light">
                <a className="navbar-brand" href="/">
                    <div className="home_intro">
                        <h2>Hi! I'm Mike Chang</h2>
                        <p>Creative, Outgoing, & Forward-thinking</p>
                    </div>
                </a>
                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="icon-bar"></span>
                    <span className="icon-bar"></span>
                    <span className="icon-bar"></span>
                </button>
                <div className="container box_1620">
                    <div className="collapse navbar-collapse offset" id="navbarSupportedContent">
                        <ul className="nav navbar-nav menu_nav ml-auto">
                            <li className="nav-item"><a className="nav-link" href="/">Home</a></li> 
                            <li className="nav-item">
                                <a href="/about" className="nav-link">
                                About
                                </a>
                            </li>
                            <li className="nav-item"><a className="nav-link" href={resume} type="application/pdf" target="_blank" rel="noopener noreferrer">Résumé</a></li>
                            <li className="nav-item"><a className="nav-link" href="/contact">Contact</a></li>
                        </ul>
                    </div> 
                </div>
            </nav>
        </div>
    )
}

export default Navbar;

$(document).ready(function () {
    $(document).click(function (event) {
        var _opened = $(".navbar-collapse").hasClass("navbar-collapse offset collapse show");
        if (_opened === true) {
            console.log("open");
            $('.navbar-collapse').collapse('hide');
        }
    });
});