import React, { Component } from 'react';
import '../App.css';
import Navbar from '../components/navbar'
import ContactMe from '../components/contactme'
import SummaryBoxes from '../components/summary_boxes'
import Footer from '../components/footer'

const Contact = () => {
  return (  
    <div className="Home">
      {/********************* HEADER *********************/}
        <header className="header_area">
          <Navbar></Navbar>
        </header>
      {/********************* HEADER *********************/}

      {/********************* Main *********************/}
          <section className="contact_area p_90_0">
            <ContactMe></ContactMe>
          </section>
          <section className="blog_categorie_area">
            <SummaryBoxes></SummaryBoxes>
          </section>
        {/* </main> */}
      {/********************* Main *********************/}

      {/********************* Footer *********************/}
        <footer className="footer_area p_120">
          <Footer></Footer>
        </footer>
      {/********************* Footer *********************/}
    </div>
  )
}

export default Contact;
