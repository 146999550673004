import React from 'react';

const NotFoundPage = () =>  {
  return (  
    <div className="my404">
      <div class="my404_box">
        <h1>404</h1>
        <p><strong>Sorry, the page you're looking for doesn't exist!</strong></p>
        <a href="/">Take me back to the home page</a>
      </div>
    </div>
  )
}

export default NotFoundPage; 