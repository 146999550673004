import React from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Switch, Redirect} from "react-router-dom";

//Pages
import HomePage from "./pages/home"
import AboutPage from "./pages/about"
import ContactPage from "./pages/contact"
import RingProject from "./pages/ring"
import ChargerProject from "./pages/charger"
import InfuseProject from "./pages/infuse"
import NotFoundPage from "./pages/404"

const App = () => {
    return (
      <Router>
        <Switch>
          <Route exact path="/" component={HomePage}/>
          <Route path="/about" component={AboutPage}/>
          <Route path="/contact" component={ContactPage}/>
          <Route path="/ring" component={RingProject}/>
          <Route path="/charger" component={ChargerProject}/>
          <Route path="/infuse" component={InfuseProject}/>
          <Route path="/404" component={NotFoundPage}/>
          <Redirect to="/404"/>
        </Switch>
      </Router>
    );
  }

export default App;