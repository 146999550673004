import React from 'react'

const Introduction = () => {
    return (
        <div>
            <div className="container box_1620">
                <div className="banner_inner d-flex align-items-center">
                    <div className="banner_content">
                        <div className="media">
                            <div className="d-flex">
                                <img src="img/personal.jpg" alt=""/>
                            </div>
                            <div className="media-body">
                                <div className="personal_text">
                                    <h6>Just a </h6>
                                    <h3>consultant</h3>
                                    <h4>looking for my next opportunity to make an impact!</h4>
                                    <p><b> Keep scrolling to learn a bit about me</b>. If you're interested in anything
                                    you see, don't hesitate to contact me. I love meeting new people whether
                                    it be virtually or in-person!</p>
                                        <div className="my_row">
                                            <div className="my_col-md-3">
                                                <div className="sub_wel_item_wrap">
                                                    <div className="sub_wel_item">
                                                        <i className="fa fa-database"></i>
                                                        <div className="text"> 50% </div>
                                                        <div className="desc-text">Tech Focused</div>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* <i className="fas fa-plus"></i> */}
                                            <div className="my_col-md-3">
                                                <div className="sub_wel_item_wrap">
                                                    <div className="sub_wel_item">
                                                        <i className="fa fa-book"></i>
                                                        <div className="text">
                                                            50% <div className="desc-text">Business Oriented</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* <i className="fas fa-equals"></i> */}
                                            <div className="my_col-md-5">
                                                <div className="wel_item_wrap">
                                                    <div className="wel_item">
                                                        <i className="fa fa-users"></i>
                                                        <div className="text"> 100% </div>
                                                        <div className="desc-text">Reliable</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    {/* <ul className="list basic_info">
                                        <li><a href="#"><i className="fa fa-calendar"></i> May 1st, 1998</a></li>
                                        <li><a href="#"><i className="lnr lnr-phone-handset"></i> +1 (540) 588-0929</a></li>
                                        <li><a href="#"><i className="fa fa-envelope"></i> changm73@yahoo.com</a></li>
                                        <li><a href="#"><i className="fa fa-home"></i> Arlington, VA</a></li>
                                    </ul> */}
                                        <div className="interests_area">
                                            <h4>Personal Interests</h4>
                                            <div className="interest_labels">
                                                <div className="personal">Netflix</div>
                                                <div className="personal">Piano</div>
                                                <div className="personal">Riddles</div>
                                                <div className="personal">Taekwondo</div>
                                                <div className="personal">Yoga</div>
                                            </div>
                                        </div>
                                    {/* <ul className="list personal_social">
                                        <li><a href="https://www.linkedin.com/in/mchang7337" target="_blank" rel="noopener noreferrer"><i className="fa fa-linkedin"></i></a></li>
                                    </ul> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Introduction;