import React from 'react';
import '../App.css';
import Navbar from '../components/navbar'
import About from '../components/about'
import Education from '../components/education'
import Experience from '../components/experience'
import Projects from '../components/projects'
import Footer from '../components/footer'

const AboutPage = () => {
  return (  
    <div className="Home">
      {/********************* HEADER *********************/}
        <header className="header_area">
          <Navbar></Navbar>
        </header>
      {/********************* HEADER *********************/}

      {/********************* Main *********************/}
          <section className="about_area p_90_40" id="aboutme">
            <About></About>
          </section>

          {/* <section className="blog_area">
            <Posts></Posts>
          </section> */}

          <section className="education_area p_90" id="education">
            <Education></Education>
          </section>

          <section className="experience_area p_90_0" id="experience">
            <Experience></Experience>
          </section>

          <section className="home_gallery_area p_60">
            <Projects></Projects>
          </section>

          <section className="section">
              
          </section>
      {/********************* Main *********************/}

      {/********************* Footer *********************/}
        <footer className="footer_area p_120">
          <Footer></Footer>
        </footer>
      {/********************* Footer *********************/}
    </div>
  )
}

export default AboutPage;