import React from 'react';
import '../App.css';
import Navbar from '../components/navbar'
import Introduction from '../components/introduction'
import Posts from '../components/posts'
import Footer from '../components/footer'

const HomePage = () => {
  return (  
    <div className="Home">
      {/********************* HEADER *********************/}
        <header className="header_area">
          <Navbar></Navbar>
        </header>
      {/********************* HEADER *********************/}

      {/********************* Main *********************/}
        {/* <main className="intro_area"> */}
          <section className="home_banner_area" id="home">
            <Introduction></Introduction>
          </section>

          <section className="blog_area">
            <Posts></Posts>
          </section>

          {/* <section className="blog_categorie_area">
            <SummaryBoxes></SummaryBoxes>
          </section> */}
          
          {/* <section className="home_gallery_area p_60">
            <Projects></Projects>
          </section> */}

          <section className="section">
              
          </section>
        {/* </main> */}
      {/********************* Main *********************/}

      {/********************* Footer *********************/}
        <footer className="footer_area p_120">
          <Footer></Footer>
        </footer>
      {/********************* Footer *********************/}
    </div>
  )
}

export default HomePage;
