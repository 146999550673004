import React from 'react'

const Posts = () => {
	return (
		<div className="container">
			<div className="section-top-border">
				<h3 className="mb-30 title_color">How I ended up where I'm at now</h3>
				<img src="img/blog/cat-post/plans.png" className="img-fluid TextWrap" alt=""/>
					{/* <img src="img/elements/d.jpg" alt="" className="img-fluid TextWrap" /> */}
					<div className="addReadMore showlesscontent">
						<p>
							I found a passion for tech early on in life. It helped that the education system I grew up in really pushed for technology, so I always
							had access to computers. I used to fool around and program batch files or edit HTML to prank my friends at school, and through these friendly pranks, I discovered
							the versatility of programming. It was programming's potential for <i>innovation</i> that led me to pursue Computer Science (CS) in college.

						</p>
						<p>
							It wasn't until later that I really showed an interest in business. For most of my life, my parents have owned a restaurant, where I started working in 
							High School. Being in the family business meant I had to do everything: dishwashing, waiting tables, washing dishes, you name it.
							But it was a great environment—interacting with the customers helped me become more <i><b>social</b></i>, and working multiple roles helped me learn <i><b>organization</b></i> and <i><b>time management</b></i>.
						</p>
						<p>	
							Starting in college, I built a foundation in computer science through various technical courses and internships in software engineering and IT. I later found that I really enjoyed working in a collaborative 
							setting and doing impactful work. So, I decided I wanted to get a client-facing job that would combine my passion for technology and business to deliver tech solutions to clients. 
							Now, I really enjoy my job in a technology driven consulting role at Deloitte, where I specialize in Data Analytics and Full-stack Development.
						</p>
						<p>
							I'm currently working at Deloitte as an analyst on the consulting track within their Risk and Financial Advisory practice. Here at Deloitte, I'm specializing in Data Analytics and Full-stack Development. 
							By working in the tech space in a consulting role, I hope gain communication and organization skills to hopefully move into a 
							
							
							
							though 
							I work with a lot of technology, I'd love to utilize my <i>communication</i> and <i>organizational</i> skills to 
							move into a <i> project management</i> role, where I'd be able to manage a whole workflow from start to finish!
						</p>
					</div>
			</div>
			<div className="section-top-border">
				<h3 className="mb-30 title_color">Leadership</h3>
				{/* <img src="img/blog/cat-post/aboutme.png" className="img-fluid TextWrap" /> */}
					<div className="addReadMore showlesscontent">
						<p>	
							Add blurb here.
						</p>
					</div>
			</div>
			<div className="section-top-border">
				<h3 className="mb-30 title_color">What the future has in store</h3>
				{/* <img src="img/blog/cat-post/aboutme.png" className="img-fluid TextWrap" /> */}
					<div className="addReadMore showlesscontent">
						<p>	
							Add blurb here.
						</p>
					</div>
			</div>
		</div>
	)
}

export default Posts;